import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress, Paper, List, ListItem, ListItemText } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { getStorage, ref, uploadBytes, getDownloadURL, connectStorageEmulator } from 'firebase/storage';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, connectFirestoreEmulator, doc, updateDoc } from 'firebase/firestore';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import BuyMeCoffeeButton from './buymeacoffee';

const Input = styled('input')({
  display: 'none',
});

// Initialize Firebase services with emulators
const storage = getStorage();
const functions = getFunctions();
const db = getFirestore();

if (process.env.NODE_ENV === 'development') {
  connectStorageEmulator(storage, 'localhost', 9199);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
}

function MainUpload({ credits, onCreditsChange }) {
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isCreatingJob, setIsCreatingJob] = useState(false);
  const [jobCreated, setJobCreated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const handleFileChange = (event) => {
    setFiles(Array.from(event.target.files));
    setErrorMessage('');
    setJobCreated(false);
  };

  const handleUpload = async () => {
    if (files.length === 0 || !user) return;
    if (credits < files.length) {
      setErrorMessage(`Nicht genügend Credits. Sie benötigen ${files.length} Credits, haben aber nur ${credits}.`);
      return;
    }

    setIsUploading(true);
    setIsCreatingJob(true);
    setErrorMessage('');

    try {
      const uploadedUrls = await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(storage, `audio/${user.uid}/${file.name}`);
          await uploadBytes(storageRef, file);
          return getDownloadURL(storageRef);
        })
      );

      // Call Firebase Function to create processing job
      const createProcessingJob = httpsCallable(functions, 'createProcessingJob');
      const result = await createProcessingJob({ audioUrls: uploadedUrls });

      console.log('Job created:', result.data);
      setJobCreated(true);

      // Deduct credits
      const newCredits = credits - files.length;
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { credits: newCredits });
      onCreditsChange(newCredits);
    } catch (error) {
      console.error('Fehler beim Hochladen:', error);
      setErrorMessage(`Fehler beim Hochladen und Erstellen des Verarbeitungsauftrags: ${error.message}`);
    } finally {
      setIsUploading(false);
      setIsCreatingJob(false);
    }
  };

  const supportedAudioFormats = 'audio/mp3,audio/wav,audio/ogg,audio/m4a,audio/aac,audio/mpeg,audio/mp4,audio/x-m4a,audio/x-wav,audio/amr,audio/3gpp';

  return (
    <Paper elevation={3} sx={{ p: 4, textAlign: 'center' }}>
      <Typography variant="h4" gutterBottom>
        Memos hochladen
      </Typography>
      {user && (
        <Typography variant="h6" gutterBottom>
          Verfügbare Credits: {credits}
        </Typography>
      )}
      <Box sx={{ my: 2 }}>
        <label htmlFor="contained-button-file">
          <Input
            accept={supportedAudioFormats}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
            disabled={!user}
            multiple
          />
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            disabled={!user || isUploading || isCreatingJob}
            sx={{
              color: user ? 'inherit' : '#BBE1FA',
              '&.Mui-disabled': {
                color: '#BBE1FA',
              },
            }}
          >
            {user ? 'Sprachmemos hochladen' : 'Bitte loggen Sie sich zuerst ein'}
          </Button>
        </label>
      </Box>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
        Unterstützte Formate: MP3, WAV, OGG, M4A, AAC, MPEG-4 Audio, AMR, 3GPP
      </Typography>
      {files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="body1" gutterBottom>
            Ausgewählte Dateien:
          </Typography>
          <List>
            {files.map((file, index) => (
              <ListItem key={index}>
                <ListItemText primary={file.name} />
              </ListItem>
            ))}
          </List>
        </Box>
      )}
      <Box sx={{ my: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!user || files.length === 0 || isUploading || isCreatingJob || credits < files.length}
        >
          {isUploading ? 'Wird hochgeladen...' : 'Hochladen und Verarbeiten'}
        </Button>
      </Box>
      {(isUploading || isCreatingJob) && (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
          <CircularProgress />
        </Box>
      )}
      {errorMessage && (
        <Typography color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
      {jobCreated && (
        <Typography variant="body1" color="primary" sx={{ mt: 2 }}>
          Verarbeitungsauftrag erfolgreich erstellt. Das Ergebnis wird Ihnen per E-Mail zugesendet.
        </Typography>
      )}

      <Box sx={{ mt: 4 }}>
        <BuyMeCoffeeButton />
      </Box>
    </Paper>
  );
}

export default MainUpload;