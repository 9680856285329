import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import LogoutIcon from '@mui/icons-material/Logout';
import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth";
import { auth } from "../firebase";
import './header.css';

// Import the logo
import logo from '../TippEsApp Logo.jpg';

function Header({ onHomeClick, onUploadClick, onFaqsClick, onCreditPurchaseClick, credits, user }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("Successfully signed in with Google", result.user);
      }).catch((error) => {
        console.error("Error signing in with Google", error);
      });
  };

  const handleLogout = () => {
    signOut(auth).then(() => {
      console.log("User signed out successfully");
    }).catch((error) => {
      console.error("Error signing out", error);
    });
  };

  return (
    <AppBar position="sticky" elevation={0} sx={{ backgroundColor: '#1B262C' }}>
      <Toolbar sx={{ justifyContent: 'space-between', flexDirection: isMobile ? 'column' : 'row', padding: isMobile ? '10px 0' : '0 16px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: isMobile ? '10px' : '0' }}>
          <img 
            src={logo}
            alt="TippEsApp Logo" 
            style={{ 
              width: isMobile ? '60px' : '80px', 
              height: isMobile ? '60px' : '80px', 
              marginRight: '10px',
              borderRadius: '50%',
              objectFit: 'cover'
            }}
          />
          <Typography variant="h6" component="div" sx={{ color: '#3282B8', fontWeight: 'bold', fontSize: isMobile ? '1.1rem' : '1.25rem' }}>
            TippEsApp Beta
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: isMobile ? 'center' : 'flex-end' }}>
          <Button sx={{ color: '#BBE1FA', fontSize: isMobile ? '0.8rem' : '1rem' }} onClick={onHomeClick}>
            HOME
          </Button>
          <Button sx={{ color: '#BBE1FA', fontSize: isMobile ? '0.8rem' : '1rem' }} onClick={onUploadClick}>
            UPLOAD
          </Button>
          <Button sx={{ color: '#BBE1FA', fontSize: isMobile ? '0.8rem' : '1rem' }} onClick={onFaqsClick}>
            FAQS
          </Button>
          {user && (
            <>
              <Typography sx={{ color: '#BBE1FA', fontSize: isMobile ? '0.8rem' : '1rem', marginRight: 2 }}>
                Credits: {credits}
              </Typography>
              <Button 
                sx={{ 
                  color: '#1B262C', 
                  backgroundColor: '#BBE1FA', 
                  fontSize: isMobile ? '0.8rem' : '1rem',
                  '&:hover': {
                    backgroundColor: '#3282B8',
                  },
                }} 
                onClick={onCreditPurchaseClick}
              >
                Buy Credits
              </Button>
            </>
          )}
          {user ? (
            <IconButton
              onClick={handleLogout}
              sx={{
                color: '#BBE1FA',
                backgroundColor: 'white',
                marginLeft: 2,
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
                width: isMobile ? '30px' : '40px',
                height: isMobile ? '30px' : '40px',
              }}
            >
              <LogoutIcon sx={{ fontSize: isMobile ? '1rem' : '1.5rem' }} />
            </IconButton>
          ) : (
            <IconButton
              onClick={signInWithGoogle}
              sx={{
                color: '#BBE1FA',
                backgroundColor: 'white',
                marginLeft: 2,
                '&:hover': {
                  backgroundColor: '#f1f1f1',
                },
                width: isMobile ? '30px' : '40px',
                height: isMobile ? '30px' : '40px',
              }}
            >
              <GoogleIcon sx={{ color: '#4285F4', fontSize: isMobile ? '1rem' : '1.5rem' }} />
            </IconButton>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;