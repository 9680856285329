import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyBaLfRBWgx4abfrUz26GV5v9Pj42afB7tY",
  authDomain: "tippesapp-30dd4.firebaseapp.com",
  projectId: "tippesapp-30dd4",
  storageBucket: "tippesapp-30dd4.appspot.com",
  messagingSenderId: "893018592621",
  appId: "1:893018592621:web:8207220197712e7f0c7664"
};

console.log('Firebase config:', firebaseConfig);

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

// Add these exports for authentication functions
export const signIn = (email, password) => auth.signInWithEmailAndPassword(email, password);
export const signOut = () => auth.signOut();
export const createUser = (email, password) => auth.createUserWithEmailAndPassword(email, password);

// New function to send email using firestore-send-email extension
export const sendEmail = async (to, subject, text, html) => {
  try {
    const mailCollection = collection(db, 'mail');
    await addDoc(mailCollection, {
      to: to,
      message: {
        subject: subject,
        text: text,
        html: html,
      },
    });
    console.log('Email queued for delivery!');
    return true;
  } catch (error) {
    console.error('Error sending email:', error);
    return false;
  }
};

// Stripe publishable key
export const STRIPE_PUBLISHABLE_KEY = 'pk_live_51PyTBF2N5wt85Z0HRdrckg5CxBXcDuFsPnu79QF6Zzxhxo2SZvnvd5pudqVVJZI6ietFP1tIqYzVMMqbuHmgGCDz006mNhtRtM';