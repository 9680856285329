import React from 'react';
import { Container, Typography, Button, Box, Paper, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  background: 'linear-gradient(0deg, #1B262C 20%, #0F4C75 90%)',
  boxShadow: '0 3px 5px 2px rgba(0, 105, 135, .3)',
}));

function Home() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 8 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <StyledPaper elevation={3}>
              <Typography variant="h3" component="h2" gutterBottom color="#BBE1FA">
                TippEsApp
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom color="#BBE1FA">
                Transkribieren und verarbeiten Sie mühelos Ihre psychosozialen Beratungsnotizen
              </Typography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4, height: '100%' }}>
              <Typography variant="body1" paragraph>
                TippEsAPP hilft psychosozialen Beratern und Psychotherapeuten, 
                ihre Beratungsnotizen effizient zu transkribieren und zu verarbeiten. 
                Nehmen Sie einfach Ihre Notizen auf, und wir wandeln sie in professionelle 
                Protokolle um.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper elevation={3} sx={{ p: 4, height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button 
                variant="contained" 
                color="primary" 
                size="large" 
                onClick={() => document.getElementById('uploadSection').scrollIntoView({ behavior: 'smooth' })}
                sx={{ py: 2, px: 4, fontSize: '1.2rem' }}
              >
                Neues Protokoll apptippen
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Home;