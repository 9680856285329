import React from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function FAQSection() {
  const faqs = [
    {
      frage: "Was ist TippEsApp?",
      antwort: "TippEsApp ist eine Web-Anwendung, um Memos von Beratungsnotizen im psychosozialen und psychotherapeutischen Kontext in professionelle Protokolle zu verwandeln."
    },
    {
      frage: "Wie sieht es aus mit Datenschutz?",
      antwort: "Datenschutz hat bei TippEsApp höchste Priorität. Unsere Server befinden sich in Europa, jedoch werden zur Verarbeitung API-Calls ins Ausland getätigt. Wir empfehlen, beim Einsprechen der Beratungsprotokolle bereits eine Anonymisierung vorzunehmen und von der Eingabe vollständiger Klientendaten abzusehen. Bei Beachtung dieser Punkte ist TippEsApp sicherer als viele E-Mail- oder Datenspeicherungsprogramme. Eine Datenschutz-Plus-Variante mit erweiterter Datenverarbeitung ausschließlich auf EU-Servern ist in Planung."
    },
    {
      frage: "Kann ich mehrere Daten gleichzeitig hochladen?",
      antwort: "Ja, die Verarbeitung mehrerer Daten ist möglich."
    },
    {
      frage: "Wie erhalte ich meine verarbeiteten Dokumente?",
      antwort: "Die verarbeiteten Dateien werden an die E-Mail-Adresse gesendet, mit der Sie sich angemeldet haben."
    },
    {
      frage: "Kann ich auf meine früheren Transkriptionen zugreifen?",
      antwort: "Nein, aus Datenschutzgründen speichern wir keine Transkriptionen auf dem Server."
    }
  ];
  
  return (
    <div>
      <Typography variant="h4" component="h2" gutterBottom>
        Häufig gestellte Fragen
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">{faq.frage}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.antwort}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}

export default FAQSection;