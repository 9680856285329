import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, TextField, CircularProgress } from '@mui/material';
import { auth } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function CreditPurchase({ onCreditsPurchased }) {
  const [purchaseAmount, setPurchaseAmount] = useState(1);
  const [paymentPreview, setPaymentPreview] = useState('€1.00');
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [message, setMessage] = useState('');
  const stripe = useStripe();
  const elements = useElements();
  const functions = getFunctions();

  useEffect(() => {
    setPaymentPreview(`€${(purchaseAmount * 1).toFixed(2)}`);
  }, [purchaseAmount]);

  const handlePurchase = async (event) => {
    event.preventDefault();
    setProcessing(true);
    setError(null);
    setMessage('');

    if (!stripe || !elements) {
      setError("Stripe.js hasn't loaded yet.");
      setProcessing(false);
      return;
    }

    try {
      const user = auth.currentUser;
      if (!user) {
        throw new Error("User not authenticated");
      }

      // Create a payment intent
      const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
      const { data: { clientSecret } } = await createPaymentIntent({
        amount: purchaseAmount,
        userId: user.uid
      });

      // Confirm the payment with Stripe
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      // Handle successful payment
      const handleSuccessfulPayment = httpsCallable(functions, 'handleSuccessfulPayment');
      const { data: { credits } } = await handleSuccessfulPayment({
        paymentIntentId: paymentIntent.id
      });

      setMessage(`Payment successful! You now have ${credits} credits.`);
      onCreditsPurchased(credits);
    } catch (error) {
      console.error("Error purchasing credits:", error);
      setError(error.message);
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Box sx={{ mt: 4, textAlign: 'center', maxWidth: '400px', margin: 'auto' }}>
      <Typography variant="h6" gutterBottom>
        Credits kaufen
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
        <TextField
          type="number"
          value={purchaseAmount}
          onChange={(e) => setPurchaseAmount(Math.max(1, parseInt(e.target.value) || 1))}
          inputProps={{ min: 1 }}
          sx={{ width: '100px', mb: 2 }}
        />
        <Typography variant="body1" gutterBottom>
          Vorschau: {paymentPreview}
        </Typography>
        <Box sx={{ width: '100%', mb: 2 }}>
          <CardElement options={{style: {base: {fontSize: '16px'}}}} />
        </Box>
        <Button 
          variant="contained" 
          color="primary" 
          onClick={handlePurchase}
          disabled={!stripe || processing}
          sx={{ width: '100%' }}
        >
          {processing ? <CircularProgress size={24} /> : "Kaufen"}
        </Button>
      </Box>
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {message && (
        <Typography color="success" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
}

export default CreditPurchase;