import React from 'react';
import { Box } from '@mui/material';

function BuyMeCoffeeButton() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
      <a href="https://www.buymeacoffee.com/tippesapp" target="_blank" rel="noopener noreferrer">
        <img 
          src="https://cdn.buymeacoffee.com/buttons/v2/default-blue.png" 
          alt="Buy Me A Coffee" 
          style={{
            height: '60px',
            width: '217px',
          }}
        />
      </a>
    </Box>
  );
}

export default BuyMeCoffeeButton;
