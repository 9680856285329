import React, { useRef, useState, useEffect } from 'react';
import { ThemeProvider, createTheme, CssBaseline, Container, Box } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISHABLE_KEY, auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import Header from './components/Header';
import Home from './components/Home';
import MainUpload from './components/MainUpload';
import FAQSection from './components/FAQSection';
import Footer from './components/Footer';
import CreditPurchase from './components/CreditPurchase';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const theme = createTheme({
  palette: {
    primary: {
      main: '#3282B8',
    },
    secondary: {
      main: '#BBE1FA',
    },
    background: {
      default: '#1B262C',
      paper: '#0F4C75',
    },
    text: {
      primary: '#BBE1FA',
      secondary: '#BBE1FA',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

function App() {
  const homeRef = useRef(null);
  const uploadRef = useRef(null);
  const faqsRef = useRef(null);
  const [credits, setCredits] = useState(0);
  const [showCreditPurchase, setShowCreditPurchase] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log('Stripe Publishable Key:', STRIPE_PUBLISHABLE_KEY);
    console.log('Stripe Promise:', stripePromise);

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        fetchUserCredits(currentUser.uid);
      } else {
        setCredits(0);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchUserCredits = async (userId) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "users", userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        setCredits(userDoc.data().credits || 0);
      } else {
        console.log("User document doesn't exist. Creating a new one.");
        const initialCredits = 2;
        await setDoc(userRef, { credits: initialCredits });
        setCredits(initialCredits);
      }
    } catch (error) {
      console.error("Error fetching or creating user document:", error);
      // You might want to set some error state here or show a notification to the user
    }
  };

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleCreditsPurchased = (newCredits) => {
    setCredits(prevCredits => prevCredits + newCredits);
    setShowCreditPurchase(false);
  };

  const handleCreditsChange = (newCredits) => {
    setCredits(newCredits);
  };

  const handleShowCreditPurchase = () => {
    setShowCreditPurchase(true);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header 
            onHomeClick={() => scrollToSection(homeRef)}
            onUploadClick={() => scrollToSection(uploadRef)}
            onFaqsClick={() => scrollToSection(faqsRef)}
            credits={credits}
            onCreditPurchaseClick={handleShowCreditPurchase}
            user={user}
          />
          <Container component="main" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', py: 4 }}>
            <Box ref={homeRef} sx={{ mb: 4 }}>
              <Home />
            </Box>
            <Box id="uploadSection" ref={uploadRef} sx={{ mb: 4 }}>
              <MainUpload credits={credits} onCreditsChange={handleCreditsChange} />
            </Box>
            {showCreditPurchase && (
              <Elements stripe={stripePromise}>
                <Box sx={{ mb: 4 }}>
                  <CreditPurchase onCreditsPurchased={handleCreditsPurchased} />
                </Box>
              </Elements>
            )}
            <Box ref={faqsRef}>
              <FAQSection />
            </Box>
          </Container>
          <Footer />
        </Box>
      </ThemeProvider>
    </Router>
  );
}

export default App;